import { Box, Paper, Stack, Typography } from '@mui/material'
import { DataGridPremium, GridToolbar, useGridApiContext } from '@mui/x-data-grid-premium'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { postRequest } from 'services/api';

const OpenOrderDetailPanel = ({row, data}) => {
  const { user, userSetting } = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const [orderItemsData, setorderItemsData] = React.useState([]);
  const apiRef = useGridApiContext();
  const [width, setWidth] = React.useState(() => {
    const dimensions = apiRef.current.getRootDimensions();
    return dimensions.viewportInnerSize.width;
  });

  const handleViewportInnerSizeChange = React.useCallback(() => {
    const dimensions = apiRef.current.getRootDimensions();
    setWidth(dimensions.viewportInnerSize.width);
  }, [apiRef]);

  const columnsItem = [
    { field: "OrderNumber", headerName: "Order Number", headerClassName: "headingbg-color", },
    { field: "orderLineNo", headerName: "order Line No", headerClassName: "headingbg-color", },
    // { field: 'OrderDetailID', headerName: 'Order Detail ID' },
    { field: "ItemNo", headerName: "Item No", headerClassName: "headingbg-color", },

    { field: "ItemDescription", headerName: "Item Description", headerClassName: "headingbg-color", },
    { field: "ORIGINALQTY", headerName: "ORIGINAL QTY", headerClassName: "headingbg-color", },
    { field: "OPENQTY", headerName: "OPEN QTY", headerClassName: "headingbg-color", },
    { field: "SHIPPEDQTY", headerName: "SHIPPED QTY", headerClassName: "headingbg-color", },
    { field: "ADJUSTEDQTY", headerName: "ADJUSTED QTY", headerClassName: "headingbg-color", },
    { field: "QTYALLOCATED", headerName: "QTY ALLOCATED", headerClassName: "headingbg-color", },

    { field: "QTYPICKED", headerName: "QTY PICKED", headerClassName: "headingbg-color", },
    { field: "UOM", headerName: "UOM", headerClassName: "headingbg-color", },
    { field: "PACKKEY", headerName: "PACK KEY", headerClassName: "headingbg-color", },
    { field: "PRODUCT_WEIGHT", headerName: "PRODUCT WEIGHT", headerClassName: "headingbg-color", },
    { field: "PRODUCT_CUBE", headerName: "PRODUCT CUBE", headerClassName: "headingbg-color", },
    { field: "ACTUALSHIPDATE", headerName: "ACTUAL SHIPDATE", headerClassName: "headingbg-color", },
    { field: "SPSTrackingNumber", headerName: "SPS Tracking Number", headerClassName: "headingbg-color", },
  ];

  const rowItems = orderItemsData.map((c, index) => ({
    id: index,
    Owner: c.Owner,
    WHSEID: c.WHSEID,
    OrderKey: c.OrderKey,
    orderLineNo: c.orderLineNo,
    OrderDetailID: c.OrderDetailID,
    ItemNo: c.ItemNo,
    ItemDescription: c.ItemDescription,
    ORIGINALQTY: c.ORIGINALQTY,
    OPENQTY: c.OPENQTY,
    SHIPPEDQTY: c.SHIPPEDQTY,
    ADJUSTEDQTY: c.ADJUSTEDQTY,
    QTYALLOCATED: c.QTYALLOCATED,
    QTYPICKED: c.QTYPICKED,
    UOM: c.UOM,
    PACKKEY: c.PACKKEY,
    PRODUCT_WEIGHT: c.PRODUCT_WEIGHT,
    PRODUCT_CUBE: c.PRODUCT_CUBE,
    ACTUALSHIPDATE: c.ACTUALSHIPDATE,
    BUYERPO: c.BUYERPO,
    FULFILLQTY: c.FULFILLQTY,
    PROCESSEDQTY: c.PROCESSEDQTY,
    QTYTOPROCESS: c.QTYTOPROCESS,
    SPSTrackingNumber: c.SPSTrackingNumber,
    OrderNumber: c.OrderNumber
  }));

  useEffect(() => {
    return apiRef.current.subscribeEvent(
      'viewportInnerSizeChange',
      handleViewportInnerSizeChange,

    );
  }, [apiRef, handleViewportInnerSizeChange]);

  useEffect(() => {
    getOrderItemsApiData();
  }, [])

  const getOrderItemsApiData = async () => {
    setIsLoading(true);
    // let headers = {
    //   "Content-Type": "application/json;",
    //   Authorization: `Bearer ${user.token}`,
    // };
    // let payload = {
    //   loadNo: row.Load_No,
    // };
    // const response = await postRequest("user/getRprt_containerHistoryDtls", payload, headers);
    let filterData= data.filter(item => item.OrderNumber === row.OrderNumber)
    setorderItemsData(filterData);
    setIsLoading(false);
  };

  return (
    <>
      <Stack
        sx={{
          py: 1,
          height: "350px",
          boxSizing: "border-box",
          position: "sticky",
          left: 0,
          width
        }}
        direction="column"
      >
        <Paper
          sx={{
            flex: 1,
            mx: "auto",
            width: "96%",
            p: 2,
            background: "#fff",
          }}
        >
          <Stack direction="column" spacing={1} sx={{ height: 1 }}>
            <Typography
              className="colordivSumHead"
              fontWeight="bold"
              variant="h6"
            >{`Details for order: #${row.OrderNumber}`}</Typography>
            <Box sx={{ width: "100%", height: 300 }}>
              <DataGridPremium
                slots={{ toolbar: GridToolbar }}
                rowSelection={true}
                columns={columnsItem}
                rows={rowItems}
                sx={{ flex: 1 }}
                hideFooter
                loading={isLoading}
              />
            </Box>
          </Stack>
        </Paper>
      </Stack>
    </>
  )
}

export default OpenOrderDetailPanel