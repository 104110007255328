import { Box, Button } from "@mui/material";
import { DataGridPremium, GridCsvExportMenuItem, GridExcelExportMenuItem, GridToolbar, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarExportContainer, GridToolbarFilterButton, GridToolbarQuickFilter } from "@mui/x-data-grid-premium";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { postRequest } from "services/api";
import OpenOrderDetailPanel from "./OpenOrderDetailPanel";

const columns = [
  {
    field: "Owner",
    headerClassName: "headingbg-color",
    headerName: "Owner",
    width: 200,
  },
  {
    field: "Warehouse",
    headerClassName: "headingbg-color",
    headerName: "Warehouse",
    width: 200,
  },
  {
    field: "OrderNumber",
    headerClassName: "headingbg-color",
    headerName: "Order Number",
    width: 200,
  },
  {
    field: "OrderRcvdDate",
    headerClassName: "headingbg-color",
    headerName: "Order Received Date",
    width: 160,
  },
  {
    field: "orderType",
    headerClassName: "headingbg-color",
    headerName: "Order Type",
    width: 150,
  },

  {
    field: "Priority",
    headerClassName: "headingbg-color",
    headerName: "Priority",
    width: 150,
  },
  {
    field: "orderStatus",
    headerClassName: "headingbg-color",
    headerName: "Order Status",
    width: 150,
  },
  {
    field: "TotalOrderLines",
    headerClassName: "headingbg-color",
    headerName: "Total Order Lines",
    width: 150,
  },
  {
    field: "PiecesOrderd",
    headerClassName: "headingbg-color",
    headerName: "Pieces Ordered",
    width: 150,
  },
  {
    field: "TotalShipped",
    headerClassName: "headingbg-color",
    headerName: "Total Shipped",
    width: 150,
  },
  {
    field: "PiecesShipped",
    headerClassName: "headingbg-color",
    headerName: "Pieces Shipped",
    width: 150,
  },
  {
    field: "RequestedShipDate",
    headerClassName: "headingbg-color",
    headerName: "Requested Ship Date",
    width: 150,
  },
  {
    field: "ScheduledShipDate",
    headerClassName: "headingbg-color",
    headerName: "Scheduled Ship Date",
    width: 150,
  },
  {
    field: "ActualShipDate",
    headerClassName: "headingbg-color",
    headerName: "Actual Ship Date",
    width: 150,
  },
  {
    field: "CarrierCode",
    headerClassName: "headingbg-color",
    headerName: "Carrier Code",
    width: 150,
  },
  {
    field: "CarrierName",
    headerClassName: "headingbg-color",
    headerName: "Carrier Name",
    width: 150,
  },
  {
    field: "ExternalOrderNo",
    headerClassName: "headingbg-color",
    headerName: "External Order No",
    width: 150,
  },
  {
    field: "BOLNumber",
    headerClassName: "headingbg-color",
    headerName: "BOL Number",
    width: 150,
  },
  {
    field: "BillTo",
    headerClassName: "headingbg-color",
    headerName: "Bill To",
    width: 150,
  },
  {
    field: "ShipTo",
    headerClassName: "headingbg-color",
    headerName: "Ship To",
    width: 150,
  },
  {
    field: "ConsigneeAddress",
    headerClassName: "headingbg-color",
    headerName: "Consignee Address",
    width: 150,
  },
  {
    field: "PurchaseOrder",
    headerClassName: "headingbg-color",
    headerName: "Purchase Order",
    width: 150,
  },
  {
    field: "tracking_PROnumber",
    headerClassName: "headingbg-color",
    headerName: "Tracking PRO Number",
    width: 150,
  },
  {
    field: "AppointmentNumber",
    headerClassName: "headingbg-color",
    headerName: "Appointment Number",
    width: 150,
  },
  {
    field: "Door",
    headerClassName: "headingbg-color",
    headerName: "Door",
    width: 150,
  },
  {
    field: "Route",
    headerClassName: "headingbg-color",
    headerName: "Route",
    width: 150,
  },
  {
    field: "Stop",
    headerClassName: "headingbg-color",
    headerName: "Stop",
    width: 150,
  },
  {
    field: "LoadNumber",
    headerClassName: "headingbg-color",
    headerName: "Load Number",
    width: 150,
  },
  {
    field: "TrailerNumber",
    headerClassName: "headingbg-color",
    headerName: "Trailer Number",
    width: 150,
  }
];

const OpenOrderReportTable = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = React.useState([]);
  const [detailData, setDetailData] = React.useState([]);
  const { startDate, endDate } = useSelector((state) => state.dashboard);
  const { user, userSetting } = useSelector((state) => state.user);

  function CustomToolbar(props) {
    return (
      <GridToolbarContainer {...props}>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        {/* <GridToolbarExport /> */}
        <CustomExportButton />
        <Box sx={{ flexGrow: 1 }} />
        <GridToolbarQuickFilter/>
      </GridToolbarContainer>
    );
  }

  const exportToExcel = () => {
    alert("buttonClicked")
  }

  const exceljsPreProcess = async ({ workbook, worksheet }) => {
    // Set document meta data
    workbook.creator = 'MUI-X team';
    workbook.created = new Date();
    worksheet.name = "Open Orders"
    
    let worksheetMy = workbook.addWorksheet("Open Orders Details");

    
    worksheetMy.state = 'visible';

    worksheetMy.columns = [
      { header: 'Owner', key: 'Owner' },
      { header: 'Warehouse', key: 'Warehouse' },
      { header: 'Order Number', key: 'OrderNumber' },
      { header: 'Order Line No', key: 'orderLineNo' },
      { header: 'Order Detail ID', key: 'OrderDetailID' },
      { header: 'Item No', key: 'ItemNo' },
      { header: 'Item Description', key: 'ItemDescription' },
      { header: 'Orginal Qty', key: 'ORIGINALQTY' },
      { header: 'Open Qty', key: 'OPENQTY' },
      { header: 'Shipped Qty', key: 'SHIPPEDQTY' },
      { header: 'Adjusted Qty', key: 'ADJUSTEDQTY' },
      { header: 'Qty Allocated', key: 'QTYALLOCATED' },
      { header: 'Qty Picked', key: 'QTYPICKED' },
      { header: 'UOM', key: 'UOM' },
      { header: 'Pack Key', key: 'PACKKEY' },
      { header: 'Product Weight', key: 'PRODUCT_WEIGHT' },
      { header: 'Product Cube', key: 'PRODUCT_CUBE' },
      { header: 'Actual Ship Date', key: 'ACTUALSHIPDATE' },
      { header: 'Buyer PO', key: 'BUYERPO' },
      { header: 'Fullfill Qty', key: 'FULFILLQTY' },
      { header: 'Processed Qty', key: 'PROCESSEDQTY' },
      { header: 'Qty To Process', key: 'QTYTOPROCESS' },
      { header: 'SPS Tracking Number', key: 'SPSTrackingNumber' },
    ];

    worksheetMy.addRows(detailData);
  
    // Customize default excel properties
    worksheet.properties.defaultRowHeight = 30;
  
    // Create a custom file header
    // worksheet.mergeCells('A1:C2');
    // worksheet.getCell('A1').value =
    //   'This is an helping document for the MUI-X team.\nPlease refer to the doc for up to date data.';
  
    // worksheet.getCell('A1').border = {
    //   bottom: { style: 'medium', color: { argb: 'FF007FFF' } },
    // };
  
    // worksheet.getCell('A1').font = {
    //   name: 'Arial Black',
    //   size: 14,
    // };
    // worksheet.getCell('A1').alignment = {
    //   vertical: 'top',
    //   horizontal: 'center',
    //   wrapText: true,
    // };
    // worksheet.addRow([]);
  };
  const exceljsPostProcess = async ({ worksheet }) => {
    // // add a text after the data
    // worksheet.addRow({}); // Add empty row
  
    // worksheet.addRow(['Those data are for internal use only']);
  };
  
  const excelOptions = {
    exceljsPreProcess,
    exceljsPostProcess,
  };

  function CustomExportButton(props) {
    return (
      <GridToolbarExportContainer {...props}>
       <GridExcelExportMenuItem options={excelOptions}/>
      </GridToolbarExportContainer>
    );
  }

  const fetchTableData = async () => {
    setIsLoading(true);
    let headers = {
      "Content-Type": "application/json;",
      Authorization: `Bearer ${user.token}`,
    };
    let payload = {
      custCode: userSetting[0].currCustomer,
      whseCode: userSetting[0].currWarehouse,
      fromDate: startDate.format("YYYY-MM-DD"),
      toDate: endDate.format("YYYY-MM-DD"),
    };
    const response = await postRequest(
      "user/getRprt_openOrders_forExcelExport",
      payload,
      headers
    );
    setData(response.data.Payload.Table);
    setDetailData(response.data.Payload.Table1)
    setIsLoading(false);
  };

  useEffect(() => {
    fetchTableData();
  }, [startDate, endDate, userSetting]);

  const rows = data.map((c, index) => ({
    id: index,
    Owner: c.Owner,
    Warehouse: c.Warehouse,
    OrderNumber: c.OrderNumber,
    OrderRcvdDate: c.OrderRcvdDate,
    OrderDate: c.OrderDate,
    orderType: c.orderType,
    Priority: c.Priority,
    orderStatus: c.orderStatus,
    TotalOrderLines: c.TotalOrderLines,
    TotalOrdered: c.TotalOrdered,
    PiecesOrderd: c.PiecesOrderd,
    TotalShipped: c.TotalShipped,
    PiecesShipped: c.PiecesShipped,
    RequestedShipDate: c.RequestedShipDate,
    ScheduledShipDate: c.ScheduledShipDate,
    ActualShipDate: c.ActualShipDate,
    CarrierCode: c.CarrierCode,
    CarrierName: c.CarrierName,
    ExternalOrderNo: c.ExternalOrderNo,
    BOLNumber: c.BOLNumber,
    BillTo: c.BillTo,
    ShipTo: c.ShipTo,
    ConsigneeAddress: c.ConsigneeAddress,
    PurchaseOrder: c.PurchaseOrder,
    tracking_PROnumber: c.tracking_PROnumber,
    AppointmentNumber: c.AppointmentNumber,
    Door: c.Door,
    Route: c.Route,
    Stop: c.Stop,
    LoadNumber: c.LoadNumber,
    TrailerNumber: c.TrailerNumber,
    sourceBOLFile: c.sourceBOLFile,
    signedBOLFile: c.signedBOLFile,
  }));

  const getDetailPanelContent = React.useCallback(
    ({ row }) => <OpenOrderDetailPanel row={row} data={detailData}/>,
    [data]
  );

  return (
    <div
      style={{
        height: 700,
        width: "100%",
        marginTop: "20px",
        background: "#fff",
      }}
    >
      <DataGridPremium
        slots={{ toolbar: CustomToolbar }}
        
        columns={columns}
        rows={rows}
        getDetailPanelHeight={() => 400}
        getDetailPanelContent={getDetailPanelContent}
          sx={{
            "& .MuiDataGrid-detailPanel": {
              overflow: "visible",
            },
          }}
      ></DataGridPremium>
    </div>
  );
};

export default OpenOrderReportTable;
