import React from "react";
import { Box, Grid } from "@mui/material";
import CardComponent from "../../../components/Card";
import DateWiseShipment from "./DateWiseShipment";
import ContainerCount from "./InvoiceCount";
import InvoiceStatus from "./InvoiceStatus";


const InvoiceInsight = () => {
  return (
    <Box className="order-insight" sx={{ marginTop: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={12}>
          <Grid container spacing={2}>
            <ContainerCount/>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={12}>
          <CardComponent>
             <InvoiceStatus />
          </CardComponent>
        </Grid>
      </Grid>
    </Box>
  );
};

export default InvoiceInsight;
