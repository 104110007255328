import { Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import DashBoardTabPanel from "./DashBoardTabPanel";
import OrderInsight from "./OrderInsight";
import AsnInsight from "./AsnInsight";
import { postRequest } from "services/api";
import InventoryInsight from "./InventoryInsight";
import ContainerInsight from "./ContainerInsight";
import { useSelector } from "react-redux";
import InvoiceInsight from "./InvoiceInsight";

const DashboardTabs = () => {
  const [value, setValue] = useState(0);
  const [navData, setNavData] = useState([]);
  const { user } = useSelector(state => state.user);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getNavData = async () => {
    const userData = JSON.parse(localStorage.getItem("user"));
    try {
      const headers = {
        Authorization: `Bearer ${userData.token}`,
      };
      const payLoad = { userId: userData.Id };
      if(JSON.parse(localStorage.getItem("navMenu"))) {
        setNavData(JSON.parse(localStorage.getItem("navMenu")));
        return
      }
      const res = await postRequest("User/GetUserNavMenus", payLoad, headers);
      localStorage.setItem('navMenu', JSON.stringify(res.data.Payload))
      setNavData(res.data.Payload);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getNavData();
  }, []);


  const navMenu = navData;

  const checkMenuPermission = (name) => {
    return navMenu && navMenu.some((menu) => menu.menuName.includes(name));
  };

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };
  return (
    <div className="dashboard-tabs-wrapper">
      <div className="dashboard-tabs">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{
            "& button": {
              borderRadius: "25px",
              backgroundColor: "#e5eaf0",
              padding: "10px 18px",
              minHeight: "36px",
              marginRight:"10px"
            },
            "& button:hover": { backgroundColor: "#a878e6", color: "#fff" },
            "& button:active": { backgroundColor: "#a878e6", color: "#fff" },
            "& button.Mui-selected": {
              backgroundColor: "#a878e6",
              color: "#fff",
            },
          }}
          TabIndicatorProps={{ hidden: true }}
        >
          
          {checkMenuPermission("Container") && (
            <Tab label="In Transit" {...a11yProps(0)} />
          )}
          {checkMenuPermission("ASN") && (
            <Tab label="Inbound" {...a11yProps(1)} />
          )}
          {checkMenuPermission("Inventory") && (
            <Tab label="Inventory" {...a11yProps(2)} />
          )}
          {checkMenuPermission("ORDERS") && (
            <Tab label="Outbound" {...a11yProps(3)} />
          )}
          {checkMenuPermission("Invoice") && (
            <Tab label="Invoice" {...a11yProps(4)} />
          )}
          
        </Tabs>
      </div>

      {checkMenuPermission("Container") && (
        <DashBoardTabPanel value={value} index={0}>
          <ContainerInsight />
        </DashBoardTabPanel>
      )}
      {checkMenuPermission("ASN") && (
        <DashBoardTabPanel value={value} index={1}>
          <AsnInsight />
        </DashBoardTabPanel>
      )} 

      {checkMenuPermission("Inventory") && (
        <DashBoardTabPanel value={value} index={2}>
          <InventoryInsight />
        </DashBoardTabPanel>
      )}

      {checkMenuPermission("ORDERS") && (
        <DashBoardTabPanel value={value} index={3}>
          <OrderInsight />
        </DashBoardTabPanel>
      )}
      
      {checkMenuPermission("Invoice") && (
        <DashBoardTabPanel value={value} index={4}>
          <InvoiceInsight />
        </DashBoardTabPanel>
      )}
    </div>
  );
};

export default DashboardTabs;
