import React from 'react'
import PageHeader from '../../components/PageHeader'
import MonthlyTransactionTable from './MonthlyTransactionTable'

const MonthlyInventoryTransaction = () => {

  return (
    <div className="dashboard-container">
      <PageHeader title="Reports:- Monthly Inventory Transaction" />
      <MonthlyTransactionTable />
    </div>
  )
}

export default MonthlyInventoryTransaction