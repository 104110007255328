import { DataGridPremium, GridToolbar } from "@mui/x-data-grid-premium";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { apiEndPoint } from "services";
import { postRequest } from "services/api";

const columns = [
  { field: 'InvoiceNum', headerClassName: "headingbg-color", headerName: 'Invoice Num', width: 200 },
  { field: 'InvoiceDate',         
     headerClassName: "headingbg-color", headerName: 'Invoice Date', width: 200 },
  { field: 'InvoiceAmt', headerClassName: "headingbg-color", headerName: 'Invoice Amt', width: 200 },
  // { field: 'INV_CURR_CODE', width: 120, headerClassName: "headingbg-color", headerName: 'Invoice Currency Code' },
  { field: 'DueDate', headerClassName: "headingbg-color", headerName: 'Due Date', width: 180 },
  // { field: 'InvoiceType', headerClassName: "headingbg-color", headerName: 'Invoice Type', width: 165 },
  { field: 'InvoiceStatus', headerClassName: "headingbg-color", headerName: 'Invoice Status', width: 150 },
  { field: 'CustomerCode', headerClassName: "headingbg-color", headerName: 'Customer Code', width: 150 },
  { field: 'Warehouse', headerClassName: "headingbg-color", headerName: 'Warehouse', width: 150 },
  { field: 'qbInvoiceAmt', headerClassName: "headingbg-color", headerName: 'Qb Invoice Amount', width: 150 },
  { field: 'qbBalanceAmt', headerClassName: "headingbg-color", headerName: 'Qb Balance Amount', width: 150 },
  { field: 'paymentStatus', headerClassName: "headingbg-color", headerName: 'Payment Status', width: 150 },
  { field: 'overDue30', headerClassName: "headingbg-color", headerName: 'Overdue 30', width: 150 },
  { field: 'overDue60', headerClassName: "headingbg-color", headerName: 'Overdue 60', width: 150 },
  { field: 'overDue90', headerClassName: "headingbg-color", headerName: 'Overdue 90', width: 150 },
  {
    field: 'SummaryInvFile',
    renderCell: (params) => {
      
        if(params.value) {
          return <a href={params.value ? `${apiEndPoint}/${params.value}`: '#'} target="_blank" rel="noreferrer">View Invoice Summary</a>
        } else {
          return params.value && params.value.toString();
        }
      
    },
    headerClassName: "headingbg-color", headerName: 'Invoice Summary'
  },
  {
    field: 'DetailedInvFile',
    renderCell: (params) => 
      {
        if(params.value) {
          return <a href={params.value ? `${apiEndPoint}/${params.value}`: '#'} target="_blank" rel="noreferrer">View Invoice Detail</a>
        } else {
          return params.value && params.value.toString();
        }
      }
      
    ,
    headerClassName: "headingbg-color", headerName: 'Invoice Detail'
  },
]

const InvoiceReportTable = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = React.useState([]);
  const { startDate, endDate } = useSelector((state) => state.dashboard);
  const { user, userSetting } = useSelector((state) => state.user);

  const fetchTableData = async () => {
    setIsLoading(true);
    let headers = {
      "Content-Type": "application/json;",
      Authorization: `Bearer ${user.token}`,
    };
    let payload = {
      invoiceNum: "all",
      custCode: userSetting[0].currCustomer,
      whseCode: userSetting[0].currWarehouse,
      fromDate: startDate.format("YYYY-MM-DD"),
      toDate: endDate.format("YYYY-MM-DD"),
    };
    const response = await postRequest(
      "user/getRprt_invoiceDtls",
      payload,
      headers
    );
    setData(response.data.Payload);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchTableData();
  }, [startDate, endDate, userSetting]);

  const rows = data.map((c) => ({
    InvoiceNum: c.InvoiceNum,
    InvoiceDate: c.InvoiceDate,
    InvoiceAmt: "$" + c.InvoiceAmt,
    INV_CURR_CODE: c.INV_CURR_CODE,
    DueDate: c.DueDate,
    BillTo: c.BillTo,
    InvoiceType: c.InvoiceType,
    InvoiceStatus: c.InvoiceStatus,
    CustomerCode: c.CustomerCode,
    Warehouse:c.Warehouse,
    qbInvoiceAmt: c.qbInvoiceAmt,
    qbBalanceAmt:c.qbBalanceAmt,
    paymentStatus:c.paymentStatus,
    overDue30: c.overDue30,
    overDue60: c.overDue60,
    overDue90: c.overDue90,
    SummaryInvFile: c.SummaryInvFile,
    DetailedInvFile:c.DetailedInvFile
  }));

  return (
    <div
      style={{
        height: 490,
        width: "100%",
        marginTop: "20px",
        background: "#fff",
      }}
    >
      <DataGridPremium
        slots={{ toolbar: GridToolbar }}
        getRowId={(row) => row?.InvoiceNum}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        columns={columns}
        rows={rows}
      ></DataGridPremium>
    </div>
  );
};

export default InvoiceReportTable;
