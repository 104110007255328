import { DataGridPremium, GridToolbar } from "@mui/x-data-grid-premium";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { postRequest } from "services/api";

const columns = [
  {
    field: "Owner",
    headerClassName: "headingbg-color",
    headerName: "Owner",
    width: 200,
  },
  {
    field: "Warehouse",
    headerClassName: "headingbg-color",
    headerName: "Warehouse",
    width: 200,
  },
  {
    field: "Item",
    headerClassName: "headingbg-color",
    headerName: "Item",
    width: 200,
  },
  {
    field: "ItemDescription",
    headerClassName: "headingbg-color",
    headerName: "Item Description",
    width: 160,
  },
  {
    field: "Pack",
    headerClassName: "headingbg-color",
    headerName: "Pack",
    width: 150,
  },

  {
    field: "BeginCases",
    headerClassName: "headingbg-color",
    headerName: "Starting Cases",
    width: 150,
  },
  {
    field: "BeginPcs",
    headerClassName: "headingbg-color",
    headerName: "Starting Pcs",
    width: 150,
  },
  {
    field: "ReceivedCases",
    headerClassName: "headingbg-color",
    headerName: "Received Cases",
    width: 150,
  },
  {
    field: "ReceivedPcs",
    headerClassName: "headingbg-color",
    headerName: "Received Pcs",
    width: 150,
  },
  {
    field: "ShippedCases",
    headerClassName: "headingbg-color",
    headerName: "Shipped Cases",
    width: 150,
  },
  {
    field: "ShippedPcs",
    headerClassName: "headingbg-color",
    headerName: "Shipped Pcs",
    width: 150,
  },
  {
    field: "AdjustedCases",
    headerClassName: "headingbg-color",
    headerName: "Adjusted Cases",
    width: 150,
  },
  {
    field: "AdjustedPcs",
    headerClassName: "headingbg-color",
    headerName: "Adjusted Pcs",
    width: 150,
  },
  {
    field: "EndCases",
    headerClassName: "headingbg-color",
    headerName: "End Cases",
    width: 150,
  },
  {
    field: "EndPcs",
    headerClassName: "headingbg-color",
    headerName: "End Pcs",
    width: 150,
  },
  {
    field: "BeginCBFT",
    headerClassName: "headingbg-color",
    headerName: "Starting CBFT",
    width: 150,
  },
  {
    field: "EndCBFT",
    headerClassName: "headingbg-color",
    headerName: "End CBFT",
    width: 150,
  },
];

const MonthlyTransactionTable = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = React.useState([]);
  const { startDate, endDate } = useSelector((state) => state.dashboard);
  const { user, userSetting } = useSelector((state) => state.user);

  const fetchTableData = async () => {
    setIsLoading(true);
    let headers = {
      "Content-Type": "application/json;",
      Authorization: `Bearer ${user.token}`,
    };
    let payload = {
      custCode: userSetting[0].currCustomer,
      whseCode: userSetting[0].currWarehouse,
      fromDate: startDate.format("YYYY-MM-DD"),
      toDate: endDate.format("YYYY-MM-DD"),
    };
    const response = await postRequest(
      "user/getRprt_monthlyItemActivity",
      payload,
      headers
    );
    setData(response.data.Payload);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchTableData();
  }, [startDate, endDate, userSetting]);

  const rows = data.map((c, index) => ({
    id: index,
    Owner: c.Owner,
    Warehouse: c.Warehouse,
    Item: c.Item,
    ItemDescription: c.ItemDescription,
    Pack: c.Pack,
    BeginCases: c.BeginCases,
    BeginPcs: c.BeginPcs,
    ReceivedCases: c.ReceivedCases,
    ReceivedPcs: c.ReceivedPcs,
    ShippedCases: c.ShippedCases,
    ShippedPcs: c.ShippedPcs,
    AdjustedCases: c.AdjustedCases,
    AdjustedPcs: c.AdjustedPcs,
    EndCases: c.EndCases,
    EndPcs: c.EndPcs,
    BeginCBFT: c.BeginCBFT,
    EndCBFT: c.EndCBFT
  }));

  return (
    <div
      style={{
        height: 700,
        width: "100%",
        marginTop: "20px",
        background: "#fff",
      }}
    >
      <DataGridPremium
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        columns={columns}
        rows={rows}
      ></DataGridPremium>
    </div>
  );
};

export default MonthlyTransactionTable;