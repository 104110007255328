import React, { useEffect, useState } from "react";
import { Avatar, Grid, Stack, Typography } from "@mui/material";
import CardComponent from "../../../components/Card";
import { useSelector } from "react-redux";
import { postRequest } from "../../../services/api";
import totalASN from "assets/total_asn.svg";
import totalVerifiedClosedASN from "assets/asn_verified_closed.svg";
import totalItemsRcvd from "assets/asn_item_recieved.svg";
import totalPiecesRcvd from "assets/asn_pieces_recieved.svg";

const ContainerCount = () => {
  const { startDate, endDate } = useSelector((state) => state.dashboard);
  const { user, userSetting } = useSelector((state) => state.user);
  const [asnCount, setAsnCount] = useState([]);

  const Icon = (props) => {
    return <img src={props.icon} alt={props.icon} width="25" />;
  };

  const formatAsnCount = (data) => {
    let mappedObj = {
      inTransit: { label: "In-Transit", icon: totalASN },
      released: {
        label: "Released",
        icon: totalVerifiedClosedASN,
      },
      delivered: {
        label: "Delivered",
        icon: totalItemsRcvd,
      },
      available: {
        label: "Available",
        icon: totalPiecesRcvd,
      },
    };
    const formatData = Object.keys(mappedObj).map((item) => {
      let sumData;
      let filterName;

      if(item === "inTransit") {
        filterName = "InTransit"
      } else if(item === "delivered") {
        filterName = "Delivered"
      } else if(item === "released") {
        filterName = "Empty Returned"
      } else if(item === "available") {
        filterName = "Availble"
      }

      let filterData =  data.filter(item => item.Status === filterName);

      if(filterData && filterData.length) {
        sumData = filterData[0].cnt
      } else {
        sumData = 0
      }
      
      
      return {
        label: mappedObj[item]["label"],
        value: sumData,
        icon: mappedObj[item]["icon"],
      };
    });

    setAsnCount(formatData);
  };

  const fetchAsnCount = async () => {
    let headers = {
      "Content-Type": "application/json;",
      Authorization: `Bearer ${user.token}`,
    };
    let payload = {
      custCode: userSetting[0].currCustomer,
      // whseCode: userSetting[0].currWarehouse,
      fromDate: startDate.format("YYYY-MM-DD"),
      toDate: endDate.format("YYYY-MM-DD"),
    };
    const response = await postRequest(
      "user/getDashCount_containersSttsWise",
      payload,
      headers
    );
    formatAsnCount(response.data.Payload);
  };

  useEffect(() => {
    fetchAsnCount();
  }, [startDate, endDate, userSetting]);

  return asnCount.map((item) => (
    <Grid item xs={3}>
      <CardComponent>
        <Stack direction="row" spacing={2}>
          <Avatar sx={{ bgcolor: "#eceaf9", width: 50, height: 50 }}>
            <Icon icon={item.icon} />
          </Avatar>
          <div className="card-title-wrap">
            <Typography variant="subtitle1" gutterBottom>
              {item.label}
            </Typography>
            <Typography variant="h5" component="p" sx={{ fontWeight: 700 }}>
              {item.value}
            </Typography>
          </div>
        </Stack>
      </CardComponent>
    </Grid>
  ));
};

export default ContainerCount;
