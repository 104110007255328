import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PrivateRoutes from "components/PrivateRoutes";
import Dashboard from "pages/Dashboard";
import Login from "pages/Login";
import Layout from "components/Layout";
import ASN from "pages/ASN";
import Orders from "pages/Orders";

import { LicenseInfo } from '@mui/x-license';
import Inventory from "pages/Inventory";
import ASNReceipt from "pages/ASNReceipt";
import ShipmentReportDateWise from "pages/ShipmentReportDateWise";
import ShipmentReportCarrierWise from "pages/ShipmentReportCarrierWise";
import InvoiceReport from "pages/InvoiceReport";
import ContainerReport from "pages/ContainerReport";
import InventoryTransaction from "pages/InventoryTransaction";
import BOLReports from "pages/BOLReports";
import EsignBol from "pages/EsignBol";
import "css/index.css"
import UserTabs from "pages/Admin/UserTabs";
import MonthlyInventoryTransaction from "pages/InventoryTransaction/MonthlyInventoryTransaction";
import OpenOrderReport from "pages/OpenOrderReport";
import Intransit from "pages/Intransit";
LicenseInfo.setLicenseKey('6e590720d0b3b5bfcff3012cf6975310Tz04Nzc2NixFPTE3NDM3OTI4NzEwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y')

function App() {
  return (
    <Router>
      <Routes>
        <Route element={<PrivateRoutes />}>
          <Route element={<Layout />}>
             <Route path="/dashboard" element={<Dashboard />} />
             <Route path="/asn" element={<ASN />} />
             <Route path="/orders" element={<Orders/>} />
             <Route path="/inventory" element={<Inventory />} />
             <Route path="/ansRec" element={<ASNReceipt />} />
             <Route path="/reportOrderDate" element={<ShipmentReportDateWise />} />
             <Route path="/reportOrderCarrier" element={<ShipmentReportCarrierWise />} />
             <Route path="/openOrderReport" element={<OpenOrderReport />} />
             <Route path="/reportInvoiceDetails" element={<InvoiceReport />} />
             <Route path="/reportContainerDetails" element={<ContainerReport />} />
             <Route path="/intransit" element={<Intransit />} />
             <Route path="/invnTrans" element={<InventoryTransaction />} />
             <Route path="/monthlyInvnTrans" element={<MonthlyInventoryTransaction />} />
             <Route path="/reportBillOfLanding" element={<BOLReports />} />
             <Route path="/bOLUploadFile" element={<EsignBol />} />
             <Route path="/userTabs" element={<UserTabs />} />
          </Route>
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Login />} />
      </Routes>
    </Router>
  );
}

export default App;