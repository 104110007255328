import React from 'react'
import PageHeader from '../../components/PageHeader'
import IntransitTable from './IntransitTable'


const Intransit = () => {
  return (
    <div className="dashboard-container">
      <PageHeader title="In Transit" hideCalendar={true}/>
      <IntransitTable />
    </div>
  )
}

export default Intransit