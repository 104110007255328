import React from 'react'
import PageHeader from '../../components/PageHeader'
import OpenOrderReportTable from './OpenOrderReportTable'


const OpenOrderReport = () => {
  return (
    <div className="dashboard-container">
      <PageHeader title="Reports:- Open Order Report" hideCalendar={true}/>
      <OpenOrderReportTable />
    </div>
  )
}

export default OpenOrderReport