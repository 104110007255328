import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { postRequest } from "../../services/api";
import {
  DataGridPremium,
  GridToolbar,
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
} from "@mui/x-data-grid-premium";
import InventoryDetailPanel from "./InventoryDetailPanel";

const columns = [
  { field: "Item", headerClassName: "headingbg-color", headerName: "Item" },
  { field: "Pack", headerClassName: "headingbg-color", headerName: "Pack" },
  {
    field: "Description",
    headerClassName: "headingbg-color",
    headerName: "Description",
  },
  {
    field: "OnHandCases",
    headerClassName: "headingbg-color",
    headerName: "On Hand QTY",
  },
  {
    field: "OnHandQuantity",
    headerClassName: "headingbg-color",
    headerName: "On Hand EA",
  },

  // { field: 'Lot', headerClassName: "headingbg-color", headerName: 'Lot' },
  // { field: 'Location', headerClassName: "headingbg-color", headerName: 'Location' },

  // { field: 'LPN', headerClassName: "headingbg-color", headerName: 'LPN' },

  {
    field: "InTransitQty",
    headerClassName: "headingbg-color",
    headerName: "In Transit Qty",
  },
  {
    field: "InTransitPcs",
    headerClassName: "headingbg-color",
    headerName: "In Transit EA",
  },
  {
    field: "AvailableQty",
    headerClassName: "headingbg-color",
    headerName: "Available Qty",
  },
  {
    field: "AvailablePcs",
    headerClassName: "headingbg-color",
    headerName: "Available Pcs",
  },
  {
    field: "AllocatedQty",
    headerClassName: "headingbg-color",
    headerName: "Allocated Qty",
  },
  {
    field: "AllocatedPcs",
    headerClassName: "headingbg-color",
    headerName: "Allocated Pcs",
  },
  {
    field: "PickedQty",
    headerClassName: "headingbg-color",
    headerName: "Picked Qty",
  },
  {
    field: "PickedPcs",
    headerClassName: "headingbg-color",
    headerName: "Picked Pcs",
  },
  {
    field: "CUBE",
    headerClassName: "headingbg-color",
    headerName: "Item Cuft",
  },
  {
    field: "TotalCUBE",
    headerClassName: "headingbg-color",
    headerName: "Total Cuft",
  },

  // { field: 'Status', headerClassName: "headingbg-color", headerName: 'Status' },
  {
    field: "GrossWeight",
    headerClassName: "headingbg-color",
    headerName: "Gross Weight",
  },
  // { field: 'NetWeight', headerClassName: "headingbg-color", headerName: 'Net Weight' },
  // { field: 'TareWeight', headerClassName: "headingbg-color", headerName: 'Tare Weight' },
  // { field: 'dataAsOfDT', headerClassName: "headingbg-color", headerName: 'Date', width: 150 },
];

const InventoryTable = ({ eventData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { user, userSetting } = useSelector((state) => state.user);
  const [balanceData, setBalanceData] = React.useState([]);

  const rows = balanceData.map((c, index) => ({
    id: index,
    Item: c.Item,
    Pack: c.Pack,
    Description: c.Description,
    OnHandCases: c.OnHandCases,
    Lot: c.Lot,
    Location: c.Location,
    LPN: c.LPN,
    OnHandQuantity: c.OnHandQuantity,
    InTransitQty: c.InTransitQty,
    AvailableQty: c.AvailableQty,
    AllocatedQty: c.AllocatedQty,
    PickedQty: c.PickedQty,
    Status: c.Status,
    GrossWeight: c.GrossWeight,
    NetWeight: c.NetWeight,
    TareWeight: c.TareWeight,
    dataAsOfDT: c.dataAsOfDT,
    InTransitPcs: c.InTransitPcs,
    AvailablePcs: c.AvailablePcs,
    AllocatedPcs: c.AllocatedPcs,
    PickedPcs: c.PickedPcs,
    CUBE: c.CUBE,
    TotalCUBE: c.TotalCUBE,
  }));

  const fetchAsnTableData = async () => {
    setIsLoading(true);
    let headers = {
      "Content-Type": "application/json;",
      Authorization: `Bearer ${user.token}`,
    };

    let payload = {
      custCode: userSetting[0].currCustomer,
      whseCode: userSetting[0].currWarehouse,
      item: "all",
      lot: "all",
      loc: "all",
      asOfDate: eventData,
    };
    const response = await postRequest(
      "user/getDashData_inventoryBal",
      payload,
      headers
    );
    setBalanceData(response.data.Payload.Table1);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchAsnTableData();
  }, [userSetting, eventData]);

  const getDetailPanelContent = React.useCallback(
    ({ row }) => <InventoryDetailPanel row={row} eventData={eventData} />,
    []
  );

  return (
    <>
      <div
        style={{
          height: 700,
          width: "100%",
          marginTop: "20px",
          background: "#fff",
        }}
      >
        <DataGridPremium
          loading={isLoading}
          columns={columns}
          rows={rows}
          initialState={{
            sorting: {
              sortModel: [{ field: "ReceiptDate", sort: "desc" }],
            },
          }}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          pinnedColumns={{ left: [GRID_DETAIL_PANEL_TOGGLE_FIELD] }}
          getDetailPanelHeight={() => 400}
          getDetailPanelContent={getDetailPanelContent}
          sx={{
            "& .MuiDataGrid-detailPanel": {
              overflow: "visible",
            },
          }}
        />
      </div>
    </>
  );
};

export default memo(InventoryTable);
