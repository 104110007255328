import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { postRequest } from "../../services/api";
import { Link } from "react-router-dom";
import {
  DataGridPremium,
  useGridApiRef,
  GridToolbar,
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
} from "@mui/x-data-grid-premium";
import OrderDetailPanel from "./OrderDetailPanel";
import { Box, Dialog, DialogContent, DialogTitle } from "@mui/material";
import PDFViewer from "components/PDFViewer";
import { apiEndPoint } from "services";

const columns = [
  {
    field: "ExternalOrderNo",
    headerClassName: "headingbg-color",
    headerName: "Customer Order",
  },
  {
    field: "OrderNumber",
    headerClassName: "headingbg-color",
    headerName: "IGD Order",
  },
  
  {
    field: "PurchaseOrder",
    headerClassName: "headingbg-color",
    headerName: "Purchase Order",
  },
  {
    field: "TotalOrdered",
    headerClassName: "headingbg-color",
    headerName: "Quantity Ordered",
  },
  {
    field: "orderStatus",
    headerClassName: "headingbg-color",
    headerName: "Order status",
  },
  {
    field: "ShipTo",
    renderCell: (params) => <Link href="#">{params.value.toString()}</Link>,
    headerClassName: "headingbg-color",
    headerName: "Ship To",
  },
  {
    field: "BillTo",
    renderCell: (params) => <Link href="#">{params.value.toString()}</Link>,
    headerClassName: "headingbg-color",
    headerName: "Bill To",
  },
  {
    field: "OrderDate",
    headerClassName: "headingbg-color",
    headerName: "PO Date",
  },
  {
    field: "ActualShipDate",
    width: 150,
    headerClassName: "headingbg-color",
    headerName: "Actual Ship Date",
  },
  {
    field: "RequestedShipDate",
    width: 150,
    headerClassName: "headingbg-color",
    headerName: "Requested Ship Date",
  },
  {
    field: "ScheduledShipDate",
    width: 150,
    headerClassName: "headingbg-color",
    headerName: "Scheduled Ship Date",
  },
  {
    field: "CarrierCode",
    headerClassName: "headingbg-color",
    headerName: "SCAC",
  },
  {
    field: "CarrierName",
    headerClassName: "headingbg-color",
    headerName: "Carrier Name",
  },
  
  // {
  //   field: "Priority",
  //   headerClassName: "headingbg-color",
  //   headerName: "Priority",
  // },
  
  {
    field: "ConsigneeAddress",
    width: 150,
    headerClassName: "headingbg-color",
    headerName: "Consignee Address",
  },
  
  {
    field: "PiecesOrderd",
    headerClassName: "headingbg-color",
    headerName: "Pieces Ordered",
  },
  {
    field: "TotalShipped",
    headerClassName: "headingbg-color",
    headerName: "Quantity Shipped",
  },
  {
    field: "PiecesShipped",
    headerClassName: "headingbg-color",
    headerName: "Pieces Shipped",
  },
  
  {
    field: "AppointmentNumber",
    width: 150,
    headerClassName: "headingbg-color",
    headerName: "Appointment Number",
  },
  { field: "Door", headerClassName: "headingbg-color", headerName: "Door" },
  {
    field: "LoadNumber",
    headerClassName: "headingbg-color",
    headerName: "Load Number",
  },
  {
    field: "TrailerNumber",
    headerClassName: "headingbg-color",
    headerName: "Trailer Number",
  },
  {
    field: "BOLNumber",
    width: 150,
    renderCell: (params) => {
      if (params.row.signedBOLFile || params.row.sourceBOLFile) {
        return <Link href="#">{params.value && params.value.toString()}</Link>;
      } else {
        return params.value && params.value.toString();
      }
    },
    headerClassName: "headingbg-color",
    headerName: "BOL Number",
  },
  {
    field: "tracking_PROnumber",
    width: 150,
    headerClassName: "headingbg-color",
    headerName: "Tracking/PRO",
  },
  {
    field: "orderType",
    headerClassName: "headingbg-color",
    headerName: "Order Type",
  },
  {
    field: "OrderRcvdDate",
    headerClassName: "headingbg-color",
    headerName: "Order Received",
  },
  {
    field: "TotalOrderLines",
    headerClassName: "headingbg-color",
    headerName: "Order Lines",
  },

  // { field: 'Route',headerClassName:"headingbg-color" , headerName: 'Route' },
  // { field: 'Stop',headerClassName:"headingbg-color" , headerName: 'Stop' },
];

const OrdersTable = () => {
  const apiRef = useGridApiRef();
  const [isLoading, setIsLoading] = useState(false);
  const [openPopUp, setOpenPopUp] = React.useState(false);
  const { startDate, endDate } = useSelector((state) => state.dashboard);
  const { user, userSetting } = useSelector((state) => state.user);
  const [orderData, setOrderData] = React.useState([]);
  const [orderDataBillShipTo, setOrderDataBillShipTo] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [orderNo, setOrderNo] = React.useState('');
  const [isSigned, setIsSigned] = React.useState(0);
  const [signedFileName, setsignedFileName] = React.useState('');
  const rows = orderData?.map((c, index) => ({
    id: index,
    OrderNumber: c.OrderNumber,
    Owner: c.Owner,
    orderType: c.orderType,
    WHSEID: c.WHSEID,
    OrderRcvdDate: c.OrderRcvdDate,
    OrderDate: c.OrderDate,
    orderStatus: c.orderStatus,
    BillTo: c.BillTo,
    ShipTo: c.ShipTo,
    Priority: c.Priority,
    CarrierCode: c.CarrierCode,
    CarrierName: c.CarrierName,
    ExternalOrderNo: c.ExternalOrderNo,

    RequestedShipDate: c.RequestedShipDate,
    ScheduledShipDate: c.ScheduledShipDate,
    ConsigneeAddress: c.ConsigneeAddress,
    TotalOrdered: c.TotalOrdered,
    TotalShipped: c.TotalShipped,
    ActualShipDate: c.ActualShipDate,
    TotalOrderLines: c.TotalOrderLines,
    PurchaseOrder: c.PurchaseOrder,
    tracking_PROnumber: c.tracking_PROnumber,
    AppointmentNumber: c.AppointmentNumber,
    Door: c.Door,
    // Route : c.Route,
    // Stop : c.Stop,
    LoadNumber: c.LoadNumber,
    TrailerNumber: c.TrailerNumber,
    BOLNumber: c.BOLNumber,
    signedBOLFile: c.signedBOLFile,
    sourceBOLFile: c.sourceBOLFile,
    isBOLSigned: c.isBOLSigned,
    PiecesOrderd: c.PiecesOrderd,
    PiecesShipped: c.PiecesShipped,
  }));

  const fetchOrderTableData = async () => {
    setIsLoading(true);
    let headers = {
      "Content-Type": "application/json;",
      Authorization: `Bearer ${user.token}`,
    };
    let payload = {
      custCode: userSetting[0].currCustomer,
      whseCode: userSetting[0].currWarehouse,
      fromDate: startDate.format("YYYY-MM-DD"),
      orderStatus: "All",
      toDate: endDate.format("YYYY-MM-DD"),
    };
    const response = await postRequest(
      "user/getDashData_orders",
      payload,
      headers
    );
    setOrderData(response.data.Payload);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchOrderTableData();
  }, [startDate, endDate, userSetting]);

  const handleCellSelectionModelChange = React.useCallback((newModel) => {
    Object.entries(newModel).forEach(([id, fields]) => {
      const updatedRow = apiRef.current.getRow(id);
      if (fields.BillTo || fields.ShipTo) {
        getBillToShipToDetails(updatedRow.OrderNumber);
        setOpenPopUp(true);
      } else if(fields.BOLNumber) {
        setOrderNo(updatedRow.OrderNumber);
        setIsSigned(updatedRow.isBOLSigned);
        const filepath = `${apiEndPoint}/`;
        setsignedFileName(filepath + updatedRow.signedBOLFile);
        if (updatedRow.isBOLSigned) {
          setOpen(true);
        } else {
          if (updatedRow.sourceBOLFile) {
            setsignedFileName(filepath + updatedRow.sourceBOLFile);
            setOpen(true);
          }
        }
      }
    });
  }, []);

  const getBillToShipToDetails = async (params) => {
    let headers = {
      "Content-Type": "application/json;",
      Authorization: `Bearer ${user.token}`,
    };
    let payload = {
      custCode: userSetting[0].currCustomer,
      whseCode: userSetting[0].currWarehouse,
      orderNo: params,
    };
    const response = await postRequest(
      "user/GetOrderBillToShipToDtls",
      payload,
      headers
    );
    setOrderDataBillShipTo(response.data.Payload);
  };

  const getDetailPanelContent = React.useCallback(
    ({ row }) => <OrderDetailPanel row={row} />,
    []
  );

  const handleClose = () => {
    setOpenPopUp(false);
  };

  return (
    <>
      <div
        style={{
          height: 700,
          width: "100%",
          marginTop: "20px",
          background: "#fff",
        }}
      >
        <DataGridPremium
          loading={isLoading}
          rowSelection={false}
          apiRef={apiRef}
          cellSelection={true}
          columns={columns}
          rows={rows}
          onCellSelectionModelChange={handleCellSelectionModelChange}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          getDetailPanelHeight={() => 400}
          getDetailPanelContent={getDetailPanelContent}
          sx={{
            "& .MuiDataGrid-detailPanel": {
              overflow: "visible",
            },
          }}
          pinnedColumns={{ left: [GRID_DETAIL_PANEL_TOGGLE_FIELD] }}
        />
      </div>

      {openPopUp && (
        <Box sx={{ width: "100%", height: "200px" }}>
          <Dialog
            open={openPopUp}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            style={{
              height: "100%",
              width: "100%",
              alignItems: "center",
            }}
            fullWidth={true}
            maxWidth={"md"}
          >
            <DialogTitle
              className="colordivSumHead font-bold"
              id="form-dialog-title"
            >
              Bill to/Ship to Details:
            </DialogTitle>
            <DialogContent>
              <Box sx={{ width: 800, height: 450 }}>
                {orderDataBillShipTo?.map((s) => {
                  return (
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          width: "100%",
                        }}
                      >
                        <table
                          style={{
                            width: "100%",
                            marginBottom: "20px",
                            tableLayout: "fixed",
                          }}
                        >
                          <thead>
                            <tr>
                              <th
                                className="colordivSumHead font-bold"
                                style={{ textAlign: "left" }}
                              >
                                Bill To Details :
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="font-bold table-align table-width">
                                Order Number
                              </td>
                              <td className="table-align">{s.OrderNumber}</td>
                            </tr>
                            <tr>
                              <td className="font-bold table-align table-width">
                                {" "}
                                BillTo Name
                              </td>
                              <td className="table-align">{s.BillTo_Name}</td>
                            </tr>
                            <tr>
                              <td className="font-bold table-align table-width">
                                {" "}
                                BillTo Address
                              </td>
                              <td className="table-align">
                                {s.BillTo_Address}
                              </td>
                            </tr>
                            <tr>
                              <td className="font-bold table-align table-width">
                                {" "}
                                BillTo City
                              </td>
                              <td className="table-align">{s.BillTo_City}</td>
                            </tr>
                            <tr>
                              <td className="font-bold table-align table-width">
                                BillTo State
                              </td>
                              <td className="table-align">{s.BillTo_State}</td>
                            </tr>
                            <tr>
                              <td className="font-bold table-align table-width">
                                BillTo Country
                              </td>
                              <td className="table-align">
                                {s.BillTo_Country}
                              </td>
                            </tr>
                            <tr>
                              <td className="font-bold table-align table-width">
                                BillTo ZipCode
                              </td>
                              <td className="table-align">
                                {s.BillTo_ZipCode}
                              </td>
                            </tr>
                            <tr>
                              <td className="font-bold table-align table-width">
                                BillTo PhoneNo
                              </td>
                              <td className="table-align">
                                {s.BillTo_PhoneNo}
                              </td>
                            </tr>
                            <tr>
                              <td className="font-bold table-align table-width">
                                BillTo Contact
                              </td>
                              <td className="table-align">
                                {s.BillTo_Contact}
                              </td>
                            </tr>
                            <tr>
                              <td className="font-bold table-align table-width">
                                BillTo VAT
                              </td>
                              <td className="table-align">{s.BillTo_VAT}</td>
                            </tr>
                          </tbody>
                        </table>

                        <table style={{ width: "100%", tableLayout: "fixed" }}>
                          <thead>
                            <tr>
                              <th
                                className="colordivSumHead font-bold"
                                style={{ textAlign: "left" }}
                              >
                                Ship To Details :
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="font-bold table-align table-width">
                                Order Number
                              </td>
                              <td className="table-align">{s.OrderNumber}</td>
                            </tr>
                            <tr>
                              <td className="font-bold table-align table-width">
                                ShipTo Name
                              </td>
                              <td className="table-align">{s.ShipTo_Name}</td>
                            </tr>
                            <tr>
                              <td className="font-bold table-align table-width">
                                ShipTo Address
                              </td>
                              <td className="table-align">
                                {s.ShipTo_Address}
                              </td>
                            </tr>
                            <tr>
                              <td className="font-bold table-align table-width">
                                ShipTo City
                              </td>
                              <td className="table-align">{s.ShipTo_City}</td>
                            </tr>
                            <tr>
                              <td className="font-bold table-align table-width">
                                ShipTo State
                              </td>
                              <td className="table-align">{s.ShipTo_State}</td>
                            </tr>
                            <tr>
                              <td className="font-bold table-align table-width">
                                ShipTo Country
                              </td>
                              <td className="table-align">
                                {s.ShipTo_Country}
                              </td>
                            </tr>
                            <tr>
                              <td className="font-bold table-align table-width">
                                ShipTo ZipCode
                              </td>
                              <td className="table-align">
                                {s.ShipTo_ZipCode}
                              </td>
                            </tr>
                            <tr>
                              <td className="font-bold table-align table-width">
                                ShipTo PhoneNo
                              </td>
                              <td className="table-align">
                                {s.ShipTo_PhoneNo}
                              </td>
                            </tr>
                            <tr>
                              <td className="font-bold table-align table-width">
                                ShipTo Contact
                              </td>
                              <td className="table-align">
                                {s.ShipTo_Contact}
                              </td>
                            </tr>
                            <tr>
                              <td className="font-bold table-align table-width">
                                ShipTo Fax
                              </td>
                              <td className="table-align">{s.ShipTo_Fax}</td>
                            </tr>
                            <tr>
                              <td className="font-bold table-align table-width">
                                ShipTo VAT
                              </td>
                              <td className="table-align">{s.ShipTo_VAT}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </>
                  );
                })}
              </Box>
            </DialogContent>
          </Dialog>
        </Box>
      )}

      {open && (
        <Dialog
          id="signDialog"
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="form-dialog-title"
          style={{
            height: "100%",
            width: "100%",
            alignItems: "left",
          }}
          fullWidth={true}
          maxWidth={"md"}
        >
          <DialogTitle
            className="colordivSumHead font-bold"
            id="form-dialog-title"
          >
            Order No: {orderNo}
          </DialogTitle>
          <DialogContent>
            <PDFViewer
              fileName={signedFileName}
              action={() => {}}
              isSigned={isSigned}
              showSign={false}
              orderNo={orderNo}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default OrdersTable;
