import React, { useEffect, useState } from "react";
import DonutChart from "../../../components/DonutChart";
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { postRequest } from "../../../services/api";
import { useSelector } from "react-redux";

const InvoiceStatus = () => {
  const { startDate, endDate } = useSelector((state) => state.dashboard);
  const { user, userSetting } = useSelector((state) => state.user);
  const [type, setType] = useState("rcptCnt");
  const [data,setData] = useState([])

  const handleChange = (e) => {
    setType(e.target.value);
  };  

  const formatShipmentData = (data) => {
    let formatResponse = {}
    if(data && data.length) {
      formatResponse = Object.entries(data[0]).map(([k, v]) => {
        let obj = {
          value: v,
          name : k
      }
      return obj
      })
    }
  //  const formatResponse = data && data.length && data.map((item) => {
  //       let count = ''
  //       if(type === 'rcptCnt') {
  //           count = item.rcptCnt
  //       } else if(type === 'CUFt') {
  //           count = item.CUFt
  //       } else {
  //           count = item.Pieces
  //       }
  //       let obj = {
  //           value: count,
  //           name : item.receiptStatus
  //       }

  //       return obj
  //  });
   setData(formatResponse)
  };

  const fetchShipmentStaus = async () => {
    let headers = {
      "Content-Type": "application/json;",
      Authorization: `Bearer ${user.token}`,
    };
    let payload = {
      custCode: userSetting[0].currCustomer,
      whseCode: userSetting[0].currWarehouse,
      fromDate: startDate.format("YYYY-MM-DD"),
      toDate: endDate.format("YYYY-MM-DD"),
    };
    const response = await postRequest(
      "user/getDashCounts_invoices",
      payload,
      headers
    );
    formatShipmentData(response.data.Payload);
  }
  

  useEffect(() => {
    fetchShipmentStaus();
  }, [startDate, endDate, type, userSetting]);

  return (
    <div>
      <Stack
        direction="row"
        sx={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Typography>Invoice</Typography>
        {/* <FormControl sx={{ m: 1, minWidth: 120 }}>
          <Select
            value={type}
            onChange={handleChange}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem value={"rcptCnt"}>Count</MenuItem>
            <MenuItem value={"CUFt"}>CUFt</MenuItem>
            <MenuItem value={"Pieces"}>Pieces</MenuItem>
          </Select>
        </FormControl> */}
      </Stack>

      <DonutChart
        chartName={"shipment status"}
        chartData={data}
      />
    </div>
  );
};

export default InvoiceStatus;
