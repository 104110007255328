import React from "react";
import ReactEcharts from "echarts-for-react";

const BarChart = ({ chartName, chartData, name ='Shipment', yAxisLabel, xAxisLabel }) => {
  let option = {
    
    title: {
      text: chartData.length=== 0 ? "No Record Found":"",
     
      left: "center",
      top: "center",
      textStyle: {
        fontSize: 20
      },
    },
    tooltip: {
      trigger: "item",
      axisPointer: {
        type: "shadow",
      },
      formatter: function (params) {
      
        return `Week: ${params.name}<br />
                ${params.seriesName}: ${params.data}`;
      }
    },
    grid: {
      left: "3%",
      right: "7%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: [
      {
        type: "category",
        data: chartData.map((item) => item.label),
        axisTick: {
          alignWithLabel: true,
        },
        // axisLabel: {
        //   rotate: 45,
        // },
        name: xAxisLabel,
        nameLocation: 'end',
        nameGap: 20
      },
    ],
    yAxis: [
      {
        type: "value",
        
        name: yAxisLabel,
        nameLocation: 'end',
        nameGap: 20,
        minInterval: 1
      },
    ],
    series: [
      {
        name: name,
        type: "bar",
        barWidth: "10",
        data: chartData.map((item) => item.value),
      },
    ],
  };
  return <ReactEcharts option={option} />;
};

export default BarChart;
