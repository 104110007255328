import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import CardComponent from "../../../components/Card";
import graph3 from "../../../assets/graph_3.png";
import graph4 from "../../../assets/graph_4.png";
import ShipmentStatus from "./ShipmentStatus";
import DateWiseShipment from "./DateWiseShipment";
import OrderCount from "./OrderCount";
import NoShipmentStatus from "./NoShipmentStatus";

const OrderInsight = () => {
  return (
    <Box className="order-insight" sx={{ marginTop: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={12}>
          <Grid container spacing={2}>
            <OrderCount />
          </Grid>
        </Grid>
        <Grid item xs={12} lg={12}>
          <CardComponent>
             <DateWiseShipment />
          </CardComponent>

        </Grid>
        <Grid item xs={12} lg={6}>
          <CardComponent>
            <ShipmentStatus />
          </CardComponent>

        </Grid>
        <Grid item xs={12} lg={6}>
          <CardComponent>
            <NoShipmentStatus />
          </CardComponent>

        </Grid>
        {/* <Grid item xs={12} lg={8}>
          <CardComponent>
            <Typography>On-Time/Delay Shipment</Typography>
            <img src={graph3} />
          </CardComponent>

        </Grid>
        <Grid item xs={12} lg={4}>
          <CardComponent>
            <Typography>Recent Activity</Typography>
            <img src={graph4} />
          </CardComponent>

        </Grid> */}
      </Grid>
    </Box>
  );
};

export default OrderInsight;
