import { DataGridPremium, GridToolbar } from "@mui/x-data-grid-premium";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { postRequest } from "services/api";
import ContainerDetailPanel from "./ContainerDetailPanel";

const columns = [
  {
    field: "Container_Number",
    headerClassName: "headingbg-color",
    headerName: "Container Number",
  },
  {
    field: "Eq_type",
    headerClassName: "headingbg-color",
    headerName: "Eq type",
  },
  {
    field: "DO_received",
    headerClassName: "headingbg-color",
    headerName: "DO received",
  },
  {
    field: "Release_Number",
    headerClassName: "headingbg-color",
    headerName: "Release Number",
  },
  {
    field: "Available_or_Ready_date",
    headerClassName: "headingbg-color",
    headerName: "Available or Ready date",
  },
  {
    field: "Port_Appt",
    headerClassName: "headingbg-color",
    headerName: "Port Appt",
  },
  {
    field: "Delivery_Date",
    width: 250,
    headerClassName: "headingbg-color",
    headerName: "Delivery Date",
  },
  {
    field: "Return_date",
    headerClassName: "headingbg-color",
    headerName: "Available orReturn date Ready date",
  },

  {
    field: "Status",
    headerClassName: "headingbg-color",
    headerName: "Status",
  },
  // {
  //   field: "Load_No",
  //   headerClassName: "headingbg-color",
  //   headerName: "Load No",
  // },
  // {
  //   field: "Customer",
  //   width: 120,
  //   headerClassName: "headingbg-color",
  //   headerName: "Customer",
  // },
  // {
  //   field: "Vessel",
  //   headerClassName: "headingbg-color",
  //   headerName: "Vessel",
  // },
  // {
  //   field: "Voyage",
  //   headerClassName: "headingbg-color",
  //   headerName: "Voyage",
  // },
  // {
  //   field: "ETA_Date",
  //   headerClassName: "headingbg-color",
  //   headerName: "ETA Date",
  // },
  // {
  //   field: "Hot_Container",
  //   headerClassName: "headingbg-color",
  //   headerName: "Hot Container",
  // },
  // { field: "Notes", headerClassName: "headingbg-color", headerName: "Notes" },
  // {
  //   field: "TrailerType",
  //   headerClassName: "headingbg-color",
  //   headerName: "Trailer Type",
  // },
  // {
  //   field: "Current_Location_Port",
  //   width: 250,
  //   headerClassName: "headingbg-color",
  //   headerName: "Current Location Port",
  // },

  // {
  //   field: "Discharge_Location_Port",
  //   headerClassName: "headingbg-color",
  //   headerName: "Discharge Location Port",
  // },
  // {
  //   field: "Return_loc_with_tracking",
  //   headerClassName: "headingbg-color",
  //   headerName: "Return loc with tracking",
  // },
  // {
  //   field: "Delivery_Location_Address",
  //   headerClassName: "headingbg-color",
  //   headerName: "Delivery Location Address",
  // },
  // { field: "LFD", headerClassName: "headingbg-color", headerName: "LFD" },
  // {
  //   field: "Customs_Release",
  //   headerClassName: "headingbg-color",
  //   headerName: "Customs Release",
  // },
  // {
  //   field: "On_VACIS_Hold",
  //   headerClassName: "headingbg-color",
  //   headerName: "On VACIS Hold",
  // },

  // {
  //   field: "Seal_No",
  //   headerClassName: "headingbg-color",
  //   headerName: "Seal No",
  // },
  // {
  //   field: "Pickup_date",
  //   headerClassName: "headingbg-color",
  //   headerName: "Pickup date",
  // },
  // {
  //   field: "SSL",
  //   width: 250,
  //   headerClassName: "headingbg-color",
  //   headerName: "SSL",
  // },
];

const ContainerReportTable = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = React.useState([]);
  const { user } = useSelector((state) => state.user);

  const fetchTableData = async () => {
    setIsLoading(true);
    let headers = {
      "Content-Type": "application/json;",
      Authorization: `Bearer ${user.token}`,
    };
    let payload = {
      customer: "all",
      status: "all",
    };
    const response = await postRequest(
      "user/getRprt_containerDtls",
      payload,
      headers
    );
    setData(response.data.Payload);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchTableData();
  }, []);

  const rows = data.map((c, index) => ({
    id: index,
    Load_No: c.Load_No,
    Customer: c.Customer,
    Vessel: c.Vessel,
    Voyage: c.Voyage,
    Container_Number: c.Container_Number,
    Status: c.Status,
    ETA_Date: c.ETA_Date,
    Hot_Container: c.Hot_Container,
    Freight_Paid: c.Freight_Paid,
    Notes: c.Notes,
    Eq_type: c.Eq_type,
    Current_Location_Port: c.Current_Location_Port,
    Discharge_Location_Port: c.Discharge_Location_Portoad_No,
    Return_loc_with_tracking: c.Return_loc_with_tracking,
    Delivery_Location_Address: c.Delivery_Location_Address,
    LFD: c.LFD,
    DO_received: c.DO_received,
    Available_or_Ready_date: c.Available_or_Ready_date,
    Delivery_Date: c.Delivery_Date,
    Customs_Release: c.Customs_Release,
    On_FDA_Hold: c.On_FDA_Hold,
    On_VACIS_Hold: c.Notes,
    Release_Number: c.Release_Number,
    Seal_No: c.Seal_No,
    Pickup_date: c.Pickup_date,
    Return_date: c.Return_date,
    SSL: c.SSL,
    Port_Appt:c.Port_Appt
  }));

  const getDetailPanelContent = React.useCallback(
    ({ row }) => <ContainerDetailPanel row={row} />,
    []
  );

  return (
    <div
      style={{
        height: 700,
        width: "100%",
        marginTop: "20px",
        background: "#fff",
      }}
    >
      <DataGridPremium
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        columns={columns}
        rows={rows}
        getDetailPanelHeight={() => 400}
        getDetailPanelContent={getDetailPanelContent}
          sx={{
            "& .MuiDataGrid-detailPanel": {
              overflow: "visible",
            },
          }}
      ></DataGridPremium>
    </div>
  );
};

export default ContainerReportTable;
