import { createSlice } from "@reduxjs/toolkit";
import { postRequest } from "../../services/api";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    loggedIn: false,
    user: JSON.parse(localStorage.getItem("user")) ?? {},
    loading: false,
    error: null,
    userSetting: JSON.parse(localStorage.getItem("userSetting")) ?? {},
    userSettingData: JSON.parse(localStorage.getItem("userSettingData")) ?? {},
  },
  reducers: {
    loginStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    loginSuccess: (state, action) => {
      state.loggedIn = true;
      state.user = action.payload;
      state.loading = false;
      state.error = null;
      localStorage.setItem("user", JSON.stringify(action.payload));
      let dateUTC = new Date(action.payload.expiration);
      let dateIST = new Date(dateUTC);
      dateIST.setHours(dateIST.getHours() + 5); 
      dateIST.setMinutes(dateIST.getMinutes() + 30);
      const newDate = new Date(dateIST)
      localStorage.setItem("userSession", JSON.stringify(newDate.getTime()));

    },
    loginFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    logout: (state) => {
      state.loggedIn = false;
      state.user = null;
      state.loading = false;
      state.error = null;
      state.userSetting = null;
      state.userSettingData = null;
      localStorage.removeItem("user");
      localStorage.removeItem("userSetting");
      localStorage.removeItem("userSettingData");
      localStorage.removeItem("userSession");
      localStorage.removeItem("navMenu");
    },
    userSetting: (state, action) => {
      state.userSetting = action.payload;
      localStorage.setItem("userSetting", JSON.stringify(action.payload));
    },
    userSettingData: (state, action) => {
      state.userSettingData = action.payload;
      localStorage.setItem("userSettingData", JSON.stringify(action.payload));
    },
  },
});

export const {
  loginStart,
  loginSuccess,
  loginFailure,
  logout,
  userSetting,
  userSettingData,
} = userSlice.actions;

export const loginUser = (credentials) => async (dispatch) => {
  dispatch(loginStart());
  try {
    let headers = {
      "Content-Type": "application/json;",
    };
    const response = await postRequest("account/Login", credentials, headers);

    if (response.data.Payload) {
      headers["Authorization"] = `Bearer ${response.data.Payload.token}`;
      const userSettingApi = await postRequest(
        "User/GetUserCurrStateSettings",
        { userId: response.data.Payload.Id },
        headers
      );
      dispatch(userSetting(userSettingApi.data.Payload));
      const userSettingDataApi = await postRequest(
        "User/GetUserMasterPgData",
        { userId: response.data.Payload.Id },
        headers
      );
      dispatch(userSettingData(userSettingDataApi.data.Payload));
      dispatch(loginSuccess(response.data.Payload));
    } else {
      dispatch(loginFailure(response.data.Message));
    }
  } catch (error) {
    dispatch(loginFailure(error.response.data));
  }
};

export const saveUserSetting = (payload) => async (dispatch) => {
  let headers = {
    "Content-Type": "application/json;",
    Authorization: `Bearer ${payload.token}`,
  };

  let obj = {
      userId: payload.userId,
      currCustomer: payload.currCustomer,
      currWarehouse: payload.currWarehouse
  }
  const response = await postRequest(
    "User/SaveUserCurrStateSettings",
    obj,
    headers
  );

  if (response.data.Payload) {
    dispatch(userSetting([{currCustomer: payload.currCustomer, currWarehouse: payload.currWarehouse}]));
  }
};

export default userSlice.reducer;
