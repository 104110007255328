import { Box } from '@mui/material'
import React from 'react'
import PageHeader from '../../components/PageHeader'
import OrdersTable from './OrdersTable'

const Orders = () => {
  return (
    <div className="dashboard-container">
      <PageHeader title="Outbound: Orders"/>
      <OrdersTable />
    </div>
  )
}

export default Orders