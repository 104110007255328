import { Box, Paper, Stack, Typography } from '@mui/material'
import { DataGridPremium, GridToolbar, useGridApiContext } from '@mui/x-data-grid-premium'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { postRequest } from 'services/api';

const ContainerDetailPanel = ({row}) => {
  const { user, userSetting } = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const [orderItemsData, setorderItemsData] = React.useState([]);
  const apiRef = useGridApiContext();
  const [width, setWidth] = React.useState(() => {
    const dimensions = apiRef.current.getRootDimensions();
    return dimensions.viewportInnerSize.width;
  });

  const handleViewportInnerSizeChange = React.useCallback(() => {
    const dimensions = apiRef.current.getRootDimensions();
    setWidth(dimensions.viewportInnerSize.width);
  }, [apiRef]);

  const columnsItem = [
    { field: 'Container_Number', headerName: 'Container Number', headerClassName: "headingbg-color", },
    { field: 'Eq_type', headerName: 'Eq type', headerClassName: "headingbg-color", },
    { field: 'DO_received', headerName: 'DO received', headerClassName: "headingbg-color", },
    { field: 'Release_Number', headerName: 'Release Number', headerClassName: "headingbg-color", },
    { field: 'Available_or_Ready_date', headerName: 'Available or Ready date', headerClassName: "headingbg-color", },
    {
      field: "Port_Appt",
      headerClassName: "headingbg-color",
      headerName: "Port Appt",
    },

    { field: 'Delivery_Date', headerName: 'Delivery Date', headerClassName: "headingbg-color", },
    { field: 'Return_date', headerName: 'Return date', headerClassName: "headingbg-color", },

    { field: 'Status', headerName: 'Status', headerClassName: "headingbg-color", },

    // { field: 'Load_No', headerName: 'Load No' },
    // { field: 'Customer', headerName: 'Customer' },
    // { field: 'Vessel', headerName: 'Vessel' },
    // { field: 'Voyage', headerName: 'Voyage' },
    
   
    // { field: 'ETA_Date', headerName: 'ETA Date' },
    // { field: 'Hot_Container', headerName: 'Hot Container' },
    // { field: 'Freight_Paid', headerName: 'Freight Paid' },
    // { field: 'Notes', headerName: 'Notes' },
    
    // { field: 'Current_Location_Port', headerName: 'Current Location Port' },
    // { field: 'Discharge_Location_Port', headerName: 'Discharge Location Port' },
    // { field: 'Return_loc_with_tracking', headerName: 'Return loc with tracking' },
    // { field: 'Delivery_Location_Address', headerName: 'Delivery Location Address' },   
    // { field: 'LFD', headerName: 'LFD' },
    
    
    
    // { field: 'Customs_Release', headerName: 'Customs Release' },
    // { field: 'On_FDA_Hold', headerName: 'On FDA Hold' },
    // { field: 'On_VACIS_Hold', headerName: 'On VACIS Hold' },
    
    // { field: 'Seal_No', headerName: 'Seal No' },
    // { field: 'Pickup_date', headerName: 'Pickup date' },
    
    // { field: 'SSL', headerName: 'SSL' },
  ];

  const rowItems = orderItemsData.map((c, index) => ({
    id: index,
    Load_No: c.Load_No,
    Customer: c.Customer,
    Vessel: c.Vessel,
    Voyage: c.Voyage,
    Container_Number: c.Container_Number,
    Status: c.Status,
    ETA_Date: c.ETA_Date,
    Hot_Container: c.Hot_Container,
    Freight_Paid: c.Freight_Paid,
    Notes: c.Notes,
    Eq_type: c.Eq_type,
    Current_Location_Port: c.Current_Location_Port,
    Discharge_Location_Port: c.Discharge_Location_Portoad_No,
    Return_loc_with_tracking: c.Return_loc_with_tracking,
    Delivery_Location_Address: c.Delivery_Location_Address,
    LFD: c.LFD,
    DO_received: c.DO_received,
    Available_or_Ready_date: c.Available_or_Ready_date,
    Delivery_Date: c.Delivery_Date,
    Customs_Release: c.Customs_Release,
    On_FDA_Hold: c.On_FDA_Hold,
    On_VACIS_Hold: c.Notes,
    Release_Number: c.Release_Number,
    Seal_No: c.Seal_No,
    Pickup_date: c.Pickup_date,
    Return_date: c.Return_date,
    SSL: c.SSL,
    Port_Appt:c.Port_Appt
  }));

  useEffect(() => {
    return apiRef.current.subscribeEvent(
      'viewportInnerSizeChange',
      handleViewportInnerSizeChange,

    );
  }, [apiRef, handleViewportInnerSizeChange]);

  useEffect(() => {
    getOrderItemsApiData();
  }, [])

  const getOrderItemsApiData = async () => {
    setIsLoading(true);
    let headers = {
      "Content-Type": "application/json;",
      Authorization: `Bearer ${user.token}`,
    };
    let payload = {
      loadNo: row.Load_No,
    };
    const response = await postRequest("user/getRprt_containerHistoryDtls", payload, headers);
    setorderItemsData(response.data.Payload);
    setIsLoading(false);
  };

  return (
    <>
      <Stack
        sx={{
          py: 1,
          height: "350px",
          boxSizing: "border-box",
          position: "sticky",
          left: 0,
          width
        }}
        direction="column"
      >
        <Paper
          sx={{
            flex: 1,
            mx: "auto",
            width: "96%",
            p: 2,
            background: "#fff",
          }}
        >
          <Stack direction="column" spacing={1} sx={{ height: 1 }}>
            <Typography
              className="colordivSumHead"
              fontWeight="bold"
              variant="h6"
            >{`Details for Load no: #${row.Load_No}`}</Typography>
            <Box sx={{ width: "100%", height: 300 }}>
              <DataGridPremium
                slots={{ toolbar: GridToolbar }}
                rowSelection={true}
                columns={columnsItem}
                rows={rowItems}
                sx={{ flex: 1 }}
                hideFooter
                loading={isLoading}
              />
            </Box>
          </Stack>
        </Paper>
      </Stack>
    </>
  )
}

export default ContainerDetailPanel