import React from 'react'
import PageHeader from '../../components/PageHeader'
import EsignBolTable from './EsignBolTable'


const EsignBol = () => {
  return (
    <div className="dashboard-container">
      <PageHeader title="e-Sign BOL" hideCalendar={true}/>
      <EsignBolTable />
    </div>
  )
}

export default EsignBol